<main class="main">
  <div id="embeddedpayments"></div>

  <div *ngIf="status() === 'authorizing'">Validating payment method...</div>
  <div *ngIf="status() === 'saving'">Saving payment method...</div>
  <div *ngIf="status() === 'loading'">Initializing secure payments form...</div>
  <div *ngIf="status() === 'error'">Unable to initialize payments form.</div>

  <div class="two-cols">
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      form="edit-card-form"
      *ngIf="status() !== 'error' && status() !== 'declined'"
      (click)="onSubmit()"
      [disabled]="status() !== 'ready'"
    >
      Save
    </button>
    <button
      mat-raised-button
      color="primary"
      form="edit-card-form"
      *ngIf="status() === 'error' || status() === 'declined'"
      (click)="initForm()"
    >
      Go Back
    </button>
  </div>
</main>
