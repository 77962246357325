import { Component, signal } from '@angular/core';
import {
  TxnSuccessData,
  type EmbeddedPayments as EmbeddedPaymentsType,
} from './deluxe-embedded-payments';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { Observable, of } from 'rxjs';

declare const EmbeddedPayments: EmbeddedPaymentsType;
declare const MessageInvoker: any;

@Component({
  selector: 'app-deluxe',
  standalone: true,
  imports: [NgIf, MatButtonModule],
  templateUrl: './deluxe.component.html',
  styleUrl: './deluxe.component.scss',
})
export class DeluxeComponent {
  // Can only post message once due to Deluxe dup events
  postedSuccessMessage = false;
  status = signal<
    'loading' | 'ready' | 'error' | 'authorizing' | 'saving' | 'declined'
  >('loading');
  constructor(private route: ActivatedRoute) {}
  get jwt() {
    const jwt = this.route.snapshot.queryParamMap.get('jwt');
    if (!jwt) {
      throw new Error('No jwt found');
    }
    return jwt;
  }
  get currency() {
    const currency = this.route.snapshot.queryParamMap.get('currency');
    if (!currency) {
      throw new Error('No currency found');
    }
    return currency;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.status.set('loading');
    this.initDeluxeScript().subscribe({
      next: () => {
        this.initEmbeddedPayments();
      },
      error: (error) => {
        console.error('Error:', error);
        this.status.set('error');
      },
    });
  }

  private initDeluxeScript() {
    if (typeof EmbeddedPayments !== 'undefined') return of({});
    const deluxeScript = environment.deluxeEmbeddedPaymentsSDKUrl;
    return new Observable((observer) => {
      const script = document.createElement('script');
      script.src = deluxeScript;
      script.onload = () => {
        // Script loaded successfully
        if (EmbeddedPayments) {
          observer.next(undefined);
          observer.complete();
        } else {
          observer.error('Failed to load EmbeddedPayments after script load');
        }
      };
      script.onerror = () => {
        observer.error('Script load error');
      };
      document.head.appendChild(script);
    });
  }

  private async initEmbeddedPayments() {
    try {
      const instance = await EmbeddedPayments.init(this.jwt as string, {
        countryCode: this.currency === 'CAD' ? 'CA' : 'US',
        currencyCode: this.currency as string,
        allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        supportedNetworks: ['visa', 'masterCard', 'amex'],
        skipCompleteAuthorization: true,
      });
      this.status.set('ready');

      instance
        .setEventHandlers({
          onTxnSuccess: (gateway, data) => {
            console.log(
              `${gateway} Transaction Succeeded: ${JSON.stringify(data)}`
            );
            this.status.set('saving');
            this.submitTokenizedCc(data);
          },
          onTxnFailed: (gateway, data) => {
            this.status.set('declined');
            console.log(
              `${gateway} Transaction Failed: ${JSON.stringify(data)}`
            );
          },
          onValidationError: (gateway, errors) => {
            this.status.set('ready');
            console.log(`Validation Error: ${JSON.stringify(errors)}`);
          },
          onCancel: (gateway) => {
            console.log(`${gateway} transaction cancelled`);
          },
          onTxnAuthorized: (gateway, data) => {
            console.log(
              `${gateway} Transaction Authorized: ${JSON.stringify(data)}`
            );
            this.status.set('saving');
            this.submitTokenizedCc(data);
          },
        })
        .render({
          containerId: 'embeddedpayments',
        });
    } catch (error) {
      console.error('Error:', error);
      this.status.set('error');
    }
  }

  public onSubmit() {
    if (this.status() !== 'ready') {
      return;
    }
    EmbeddedPayments.pay(this.jwt as string);
    this.status.set('authorizing');
  }

  onCancel() {
    this.postMessage({
      type: 'cancel',
      data: {},
    });
  }

  public submitTokenizedCc(data: TxnSuccessData) {
    if (this.postedSuccessMessage) {
      throw new Error('Already posted success message');
    }
    this.postedSuccessMessage = true;
    const { jwt } = data;
    this.postMessage({
      type: 'success',
      data: {
        currency: this.currency ?? 'USD',
        processor: 'deluxe',
        jwt,
      },
    });
  }

  private postMessage(message: Record<string, unknown>) {
    try {
      MessageInvoker.postMessage(JSON.stringify(message));
    } catch (error) {
      console.error('Error posting native message:', error);
    }
    try {
      window.postMessage(message, '*');
    } catch (error) {
      console.error('Error posting iframe message:', error);
    }
  }
}
